<template>
    <div class="loginPage">
        <div @click="$router.go(-1)">
            <svg-icon class="backBtn" iconClass="back"></svg-icon>
        </div>
        <div class="login_title">欢迎登录</div>
        <div class="login_subtitle">手机号登录/注册</div>
        <van-field class="vanInput mb10" v-model="phoneVal" :border="false" placeholder="手机号" />
        <van-field class="vanInput" v-model="codeVal" :border="false" placeholder="验证码">
            <template #button>
                <div class="loading" v-if="loading">
                    <van-loading type="spinner" color="#ec8737" size="20" />
                </div>
                <div class="sendCode" v-else-if="timer">{{timer}}S</div>
                <div class="sendCode" v-else @click="sendCode">发送验证码</div>
            </template>
        </van-field>
        <div class="loginBtn" @click="userLogin">登录</div>
    </div>
</template>

<script>
import { Field, Loading, Toast } from 'vant';
import { userLogin } from "@/api/user";
import { setSessionItem} from "@/utils/longStorage";
import { getDevType } from "@/utils/index";
export default {
    name: 'Login',
    components: {
        [Field.name]: Field,
        [Loading.name]: Loading,
    },
    data() {
        return {
            phoneVal: "", // 手机号
            codeVal: "", // 验证码
            timer: 0, // 倒计时时间
            loading: false, // loading
        }
    },
    created() {},
    methods: {
        // 倒计时
        countdown() {
            this.timer = 10;
            let countdown = setInterval(() => {
                this.timer--;
                if (this.timer <= 0) {
                    clearInterval(countdown);
                }
            }, 1000);
        },
        // 发送验证码
        async sendCode() {
            // if (!this.phoneVal) {
            //     alert('请输入手机号');
            //     return;
            // }
            // this.loading = true;
            // let req = { 
            //     mobile: "+86" + this.phoneVal,
            //     type: 2,
            // };
            // 测试代码
            // setTimeout(() => {
            //     this.loading = false;
            //     this.countdown();
            // }, 3000)
            // let ret = await this.$Api(captcha, req);
            // this.loading = false;
            // if (ret && ret.code == 200) {
            //     this.countdown();
            // } else {
            //     console.log(ret.tip || "验证码发送失败");
            // }
            
        },
        // 登录
        async userLogin() {
            if (!this.phoneVal) {
                alert('请输入手机号');
                return;
            }
            if (!this.codeVal) {
                alert('请输入验证码');
                return;
            }
            let req = {
                mobile: "+86" + this.phoneVal,
                code: this.codeVal,
                devID: this.$store.getters.UID,
                sysType: getDevType(),
            }
            // 测试代码
            // setSessionItem("userInfo", JSON.stringify({name: "张三"}));
            // this.$store.commit("user/SET_USERINFO", {name: "张三"}) 
            let ret = await this.$Api(userLogin, req);
            if (ret && ret.code == 200) {
                setSessionItem("userInfo", JSON.stringify(ret.data));
                this.$store.dispatch("SET_USERINFO", ret.data);
                // this.$router.go(-1);
            } else {
                Toast(ret.tip || "登录失败");
            }
        },
    }
}
</script>

<style lang="scss" scoped>
.loginPage {
    padding: 0 16px;
    .backBtn {
        width: 18px;
        height: 16px;
        margin-top: 12px;
    }
    .login_title {
        font-size: 34px;
        letter-spacing: 0.61px;
        margin-top: 38px;
    }
    .login_subtitle {
        font-size: 17px;
        margin-bottom: 53px;
    }
    .vanInput {
        height: 44px;
        border-radius: 44px;
        background: #474a51;
        /deep/ .van-field__control {
            color: #fff;
        }
        ::placeholder {
            color: #fff;
        }
        .sendCode {
            width: 60px;
            text-align: center;
            font-size: 12px;
            color: #ec8737;
        }
        .loading {
            width: 60px;
            display: flex;
            align-items: center;
            justify-content: center;
        }
    }
    .loginBtn {
        color: #191d26;
        font-size: 15px;
        height: 44px;
        line-height: 44px;
        border-radius: 44px;
        margin-top: 40px;
        text-align: center;
        background-image: linear-gradient(to bottom , #ff7f0f, #e38825);
    }
}
.mb10 {
    margin-bottom: 10px;
}
</style>