<template>
  <div class="addUser_warp">
    <Search @search="search" />
    <!--    userItem-->
    <div class="user_box" v-if="!isLoading">
      <h2 v-if="!isNotRecomment">为你推荐</h2>
      <PullRefresh :loading="loading" :finished="finished" @onLoad="onLoad" :isHigehtMax="true" :isNoData="isNoData" :disabled="true" :error="error">
        <UserFocusItem :userInfo="userInfo" v-for="userInfo in list" :key="userInfo.id" class="user_item" />
      </PullRefresh>
    </div>
    <Loading v-else />
  </div>
</template>
<script>
import Search from '@/components/Search';
import UserFocusItem from '@/components/UserFocusItem';
import PullRefresh from '@/components/PullRefresh';
import { getRecommendUserlist } from '@/api/information';
import { searchTagorUser } from '@/api/community';
import { Toast } from 'vant';
import Loading from '@/components/Loading';
import Login from '@/views/mine/login';

export default {
  name: 'addUser',
  components: {
    Search,
    UserFocusItem,
    PullRefresh,
    Loading,
  },
  data() {
    return {
      isLoading: false,
      params: {
        pageNumber: 1,
        pageSize: 20,
      },
      isNotRecomment: false,
      list: [],
      loading: true,
      finished: false,
      isNoData: false,
      error: false,
      keyword: '',
    };
  },
  created() {
    this.getRecommentList();
  },
  methods: {
    //为你推荐
    async getRecommentList(type) {
      let req = {
        pageNumber: String(this.params.pageNumber),
        pageSize: String(this.params.pageSize),
      };
      try {
        let res = await this.$Api(getRecommendUserlist, req);
        this.loading = false;
        if (res.code === 200) {
          let list = res.data.list || [];
          if (type === 'refresh') {
            this.list = list;
          } else {
            this.list = this.list.concat(list);
          }
          if (this.params.pageNumber == 1 && this.list.length == 0) {
            this.isNoData = true;
          }
          if (!res.data.hasNext) {
            this.finished = true;
          }
        } else {
          this.error = true;
        }
      } catch (e) {
        this.loading = false;
        this.error = true;
      }
    },
    async getList(type) {
      let req = {
        keyWords: [this.keyword],
        realm: 'user',
        pageNumber: this.params.pageNumber,
        pageSize: this.params.pageSize,
      };
      let res = await this.$Api(searchTagorUser, req);
      this.loading = false;
      this.isLoading = false;
      if (res.code === 200) {
        let list = res.data.list || [];
        if (type === 'refresh') {
          this.list = list;
        } else {
          this.list = this.list.concat(list);
        }
        if (this.params.pageNumber == 1 && this.list.length == 0) {
          this.isNoData = true;
        }
        if (!res.data.hasNext) {
          this.finished = true;
          return;
        }
      } else {
        if (this.params.pageNumber == 1) {
          this.isNoData = true;
        }
      }
    },
    search(val) {
      if (!val) {
        Toast({ message: '请输入关键字', position: 'top' });
        return;
      }
      this.isNotRecomment = true;
      this.params.pageNumber = 1;
      this.params.pageSize = 20;
      this.isLoading = true;
      this.isNoData = false;
      this.finished = false;
      this.keyword = val;
      this.getList('refresh');
    },
    onLoad() {
      this.loading = true;
      if (this.error) {
        this.error = false;
      } else {
        this.params.pageNumber++;
      }
      if (this.isNotRecomment) {
        this.getList();
      } else {
        this.getRecommentList();
      }
    },
  },
};
</script>
<style lang="scss" scoped>
.addUser_warp {
  height: 100%;

  .user_box {
    height: calc(100% - 90px);
    > h2 {
      font-size: 14px;
      padding: 16px 12px 14px;
    }

    .user_item {
      padding-top: 10px;

      /deep/ .post-avatar {
        width: 57px;
        height: 57px;
      }
    }
  }
}
</style>
