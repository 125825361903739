<template>
  <div class="user_focus_warp">
    <div class="post-top">
      <div class="post-top-left flex-center-center">
        <router-link
          class="post-avatar"
          :to="{ path: '/userHomePage', query: { uid: userInfo.uid } }"
          :class="{ isCertifica: userInfo.merchantUser == 1 }"
        >
          <ImgDecypt :src="userInfo.portrait" round />
          <div class="certification" v-if="userInfo.superUser == 1">
            <Vlogo :vid="userInfo.uid" />
          </div>
          <svg-icon class="certificaBorder" iconClass="certificaBorder" v-if="userInfo.merchantUser == 1" />
          <!-- <svg-icon class="certification" iconClass="certification" v-if="userInfo.superUser == 1"></svg-icon> -->
        </router-link>
        <div class="post-info">
          <div class="username_box">
            <router-link
              tag="span"
              :to="{ path: '/userHomePage', query: { uid: userInfo.uid } }"
              :class="{ vipName:  userInfo.vipLevel > 0 }"
            >
              {{ userInfo.name || '未知' }}
            </router-link>
            <svg-icon class="user_icon" iconClass="crown" v-if="userInfo.vipLevel > 0"></svg-icon>
            <div class="user_icon" v-for="icon in userIcons" :key="icon.number">
              <ImgDecypt :src="icon.imgUrl" v-if="icon.isExpire" />
            </div>
          </div>
          <!--显示粉丝-->
          <div class="fan">
            <div class="fan_text">粉丝：{{ userInfo.fansCount || userInfo.fans || 0 }}</div>
          </div>
        </div>
      </div>
      <div class="post-top-right" v-if="!userInfo.hasFollowed && $store.getters.userInfo.uid != userInfo.uid" @click="focus">
        <svg-icon iconClass="focusOn"></svg-icon>
      </div>
    </div>
  </div>
</template>
<script>
import Vlogo from '@/components/Vlogo';
import ImgDecypt from '@/components/ImgDecypt';
import { Toast } from 'vant';
import { careOrcancle } from '@/api/user';
import { getMedal } from '@/utils/getConfig';

export default {
  props: {
    userInfo: {
      type: Object,
      required: true,
      default: () => {
        return {};
      },
    },
  },
  components: {
    Vlogo,
    ImgDecypt,
  },
  computed: {
    userIcons() {
      return getMedal(this.userInfo?.awardsExpire || []) || [];
    },
  },
  methods: {
    //关注
    async focus() {
      if (this.userInfo?.hasFollowed) {
        return;
      }
      let uid = this.userInfo?.uid;
      let req = {
        followUID: parseInt(uid),
        isFollow: true,
      };
      if (!uid) {
        Toast('当前用户信息错误');
        return;
      }
      try {
        let res = await this.$Api(careOrcancle, req);
        if (res.code === 200) {
          this.userInfo.hasFollowed = true;
          Toast('关注成功');
          return;
        }
        Toast(res.tip || '关注失败');
      } catch (e) {
        Toast('关注失败');
      }
    },
  },
};
</script>
<style lang="scss" scoped>
.user_focus_warp {
  background: #ffffff;
  .post-top {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 4px;
    padding: 10px 12px;
    .post-top-left {
      .post-avatar {
        width: 50px;
        height: 50px;
        border-radius: 50%;
        margin-right: 8px;
        position: relative;
        .certification {
          width: 20.1px;
          height: 20.2px;
          position: absolute;
          right: 0;
          bottom: 0;
          overflow: hidden;
          border-radius: 50%;
          -webkit-backface-visibility: hidden;
          -moz-backface-visibility: hidden;
          -webkit-transform: translate3d(0, 0, 0);
          -moz-transform: translate3d(0, 0, 0);
        }
        .certificaBorder {
          position: absolute;
          top: -8.2px;
          width: 100%;
          font-size: 25px;
          z-index: 1;
        }
      }

      .isCertifica {
        border-radius: 50%;
        border: 1.5px solid #d82d0b;
      }

      .post-info {
        width: 190px;
        display: flex;
        flex-direction: column;

        .username_box {
          font-size: 18px;
          display: flex;
          align-items: center;

          .user_icon {
            width: 32px;
            height: 32px;
            padding-left: 5px;
            /deep/ .vanImage {
              background: #00000000 !important;
            }
          }

          span {
            letter-spacing: -0.41px;
            color: #333333;
            overflow: hidden;

            text-overflow: ellipsis; //文本溢出显示省略号
            display: -webkit-box;
            -webkit-line-clamp: 2;
            -webkit-box-orient: vertical;
          }
          .vipName {
            color: #ff678f;
          }
        }

        .fan {
          padding-top: 8px;
          font-size: 11px;

          .fan_text {
            color: #666666;
          }
        }
      }
    }

    .post-top-right {
      svg {
        width: 70px;
        height: 25px;
      }
    }
  }
}
</style>
