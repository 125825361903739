import axios from "@/utils/request";

//获取会话列表
export function getSessionList(data) {
    return axios.get(`msg/session/list`, {params: data})
}

//删除消息
export function delMessage(data) {
    return axios.post(`msg/session/del`, data)
}

//获取消息列表
export function getMessageList(data) {
    return axios.get(`msg/message/list`, {params: data})
}

//发送消息
export function sendPriLetter(data) {
    return axios.post(`msg/priLetter/add`, data)
}

//发送已读
export function sendMsgRead(data) {
    return axios.post(`msg/read`, data)
}

//获取公告
export function getAnnouList(data) {
    return axios.get(`annou/msg/list`, {params: data})
}

//获取推荐用户
export function getRecommendUserlist(data) {
    return axios.get(`recommend/msg/user/list`, {params: data})
}
